<template>
	<div>
		<Cover></Cover>

		<SFC></SFC>

		<v-img
			:src="require('@/assets/images/features/sdc2022-team3.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<Highlights></Highlights>

		<v-img
			:src="require('@/assets/images/features/sdc2022-site1.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<Affiliates></Affiliates>

		<v-img
			:src="require('@/assets/images/features/sdc2018-oc.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<FollowUs></FollowUs>
	</div>
</template>



<script>
import Cover from "@/views/home/sections/Cover.vue";
import Highlights from "@/views/home/sections/Highlights.vue";
import Affiliates from "@/views/home/sections/Affiliates.vue";
import FollowUs from "@/views/home/sections/FollowUs.vue";
import SFC from "@/views/home/sections/SFC.vue";

export default {
	name: "Home",

	components: {
		Cover,
		Highlights,
		Affiliates,
		FollowUs,
		SFC,
	},
};
</script>

<style scope>
.img_fixed .v-image__image {
	background-attachment: fixed !important;
}
</style>
