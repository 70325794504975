<template>
	<section>
		<v-theme-provider dark>
			<base-section id="social" class="accent text-center">
				<!-- <base-icon class="mb-8"> mdi-rss </base-icon> -->

				<base-section-heading :title="$t('followUs')">
				</base-section-heading>

				<div class="d-flex justify-center">
					<div v-for="(social, i) in socials" :key="i">
						<v-img
							:src="
								require(`@/assets/images/features/${social.name}.png`)
							"
							contain
							height="100"
							width="150"
						/>
						<div class="text-center mt-1">
							<v-icon>mdi-{{ social.icon }}</v-icon>
							<span class="text-caption ml-1">{{
								$t(social.name)
							}}</span>
						</div>
					</div>
				</div>
			</base-section>
		</v-theme-provider>
	</section>
</template>

<script>
export default {
	data: () => ({
		socials: [
			{
				name: "wechat",
				icon: "wechat",
			},
			{
				name: "weibo",
				icon: "sina-weibo",
			},
			{
				name: "tiktok",
				icon: "music-note-outline",
			},
		],
	}),
};
</script>
