<template>
	<section>
		<v-img
			:src="require('@/assets/images/features/index_bg.png')"
			height="calc(100vh - 64px)"
			class="white--text img_fixed"
			gradient="to bottom, rgba(0, 0, 0, .8), rgba(0, 0, 0, .2)"
		>
			<v-container class="fill-height d-flex align-end">
				<v-responsive
					class="d-flex align-center mx-auto w-100 h-100"
					max-width="1050"
					style="margin-bottom: 10%"
				>
					<base-subheading size="text-h4"
						>{{ $t("subtitle") }}
					</base-subheading>

					<base-heading size="text-h2" class="text-uppercase">{{
						$t("title")
					}}</base-heading>

					<base-body>
						{{ $t("description") }}
					</base-body>

					<div
						:class="
							$vuetify.breakpoint.smAndDown
								? 'flex-column align-start'
								: 'align-center'
						"
						class="d-flex flex-wrap"
					>
						<!-- <base-btn> Discover More </base-btn> -->
						<v-btn color="primary" to="/about/1">{{
							$t("discoverMore")
						}}</v-btn>

						<span class="mx-6">{{ $t("or") }}</span>

						<v-btn outlined color="primary" to="/archives/2022">{{
							$t("followUp")
						}}</v-btn>
					</div>
				</v-responsive>
			</v-container>
		</v-img>
	</section>
</template>

<script>
import Subheading from "@/components/base/Subheading.vue";
export default {
	components: { Subheading },

	provide: {
		theme: { isDark: true },
	},

	computed: {
		minHeight() {
			const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
			return `calc(${height} - ${this.$vuetify.application.top}px)`;
		},
	},
};
</script>

<style scoped>
.img_fixed .v-image__image {
	background-attachment: fixed;
}
</style>