<template>
	<base-section>
		<v-container>
			<base-section-heading :title="$t('title-2')">
				{{ $t("subtitle-2") }}
			</base-section-heading>

			<v-row>
				<v-col cols="6">
					<v-row>
						<v-col v-for="card in cards" :key="card.title" cols="3">
							<base-info-card align="center" v-bind="card" />
						</v-col>
					</v-row>

					<v-alert border="top" text color="primary">
						{{ $t("text-2") }}
					</v-alert>

					<base-section-heading :title="$t('title-3')" class="mt-9">
						{{ $t("subtitle-3") }}
					</base-section-heading>

					<div class="text-center">
						<v-btn color="primary" large to="/videos"
							><v-icon left> mdi-google-play </v-icon>
							{{ $t("moreVideos") }}</v-btn
						>
					</div>
				</v-col>

				<v-col cols="6">
					<iframe
						src="//player.bilibili.com/player.html?aid=754888063&bvid=BV1yk4y1C7aE&cid=244907802&page=1"
						scrolling="no"
						border="0"
						frameborder="no"
						framespacing="0"
						allowfullscreen="true"
						width="100%"
						height="560"
					>
					</iframe>
				</v-col>
			</v-row>
		</v-container>
	</base-section>
</template>

<script>
export default {
	data: () => ({
		cards: [
			{
				icon: "mdi-earth",
				title: "19",
				subtitle: "countries",
				text: "",
			},
			{
				icon: "mdi-school",
				title: "58",
				subtitle: "schools",
				text: "",
			},
			{
				icon: "mdi-flag",
				title: "41",
				subtitle: "teams",
				text: "",
			},
			{
				icon: "mdi-account-school",
				title: "2,000",
				subtitle: "decathletes",
				text: "",
			},
		],
	}),
};
</script>
