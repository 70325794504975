<template>
    <base-section id="affiliates">
        <v-container>
            <base-section-heading :title="$t('affiliates')" />

            <v-slide-group>
                <template v-for="(affiliate, i) in affiliates">
                    <v-slide-item :key="i" class="align-self-center">
                        <base-img
                            :src="
                                require(`@/assets/images/features/logo-${affiliate}.png`)
                            "
                            contain
                            height="60"
                            width="120"
                        />
                    </v-slide-item>

                    <v-responsive
                        v-if="i < 5"
                        :key="`divider-${i}`"
                        class="text-center"
                        height="60"
                    >
                        <v-divider vertical />
                    </v-responsive>
                </template>
            </v-slide-group>
        </v-container>
    </base-section>
</template>

<script>
export default {
    data: () => ({
        affiliates: ["nea", "coda", "nea", "coda", "nea", "coda"],
    }),
};
</script>